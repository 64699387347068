@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.date-picker-calendar {
	.week-days {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		height: 24px;
		box-sizing: border-box;
		border-top: 1px solid $grey-medium;
		border-bottom: 1px solid $grey-medium;
		padding-left: 10px;
		padding-right: 10px;

		&__item {
			@extend %text-bold;
			text-align: center;
			width: 50px;
			font-size: 1.4rem;
			line-height: 1.8rem;
			color: $grey-dark;
		}
	}

	.datepicker-calendar-list-item {
		padding-top: 17px;
	}
}

.date-picker-calendar-tile {
	&__tile {
		@extend %font-regular;
		margin: 4px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border: none;
		background: none;
		color: $black;
		font-size: 1.4rem;
		box-sizing: border-box;
		width: 50px;
		aspect-ratio: 1 / 1;

		&--disabled {
			pointer-events: none;
			color: $grey-medium;
		}
	}
}

.react-calendar__tile--range {
	&.react-calendar__tile--rangeStart,
	&.react-calendar__tile--rangeEnd,
	&.react-calendar__tile--hasActive {
		abbr {
			height: 100%;
			width: 100%;
			color: white;
			background: $active-color;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
