@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../../.styles/collapse";

.push {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	background: $color-primary;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	z-index: 4;

	&__link {
		text-decoration: underline;
		color: white;
		cursor: pointer;
	}

	@include gridle_state(sm) {
		max-width: 450px;

		&--animate {
			animation: shake 5s linear 1s 1 normal;
		}

		&--opened {
			animation: none;
		}
	}

	.rc-collapse-item {
		&-active {
			.rc-collapse-header {
				.push-header__arrow svg {
					transform: rotate(-180deg);
				}
			}
		}
	}

	.rc-collapse-header {
		outline: none;

		.push-header__arrow svg {
			transform: rotate(0deg);
			transition: transform 0.3s ease-in-out;
		}
	}

	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		color: white;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		user-select: none;

		// any link in the push header
		a {
			color: inherit;
			font-family: inherit;
			font-size: inherit;
		}

		@include gridle_state(md) {
			padding: 15px 20px;

			&:hover {
				cursor: pointer;
			}
		}

		&__picto {
			.icon {
				height: 30px;
				width: 30px;
			}
		}

		&__text {
			@extend %text-bold;
			font-size: 12px;
			text-align: center;
			text-transform: uppercase;
			margin: 0 10px;
			color: white;

			@include gridle_state(md) {
				font-size: 16px;
			}
		}
	}

	&__content {
		padding: 0 10px 10px;
		color: white;
		a {
			color: white;
		}
	}

	&__title {
		margin: 10px 0 5px;
		text-decoration: underline;
	}

	&__header {
		div:first-of-type {
			font-size: 1.2rem;
		}

		div:last-of-type {
			@extend %text-bold;
			color: white;
			font-size: 1.5rem;
		}

		@include gridle_state(sm) {
			div:first-of-type {
				font-size: 1.4rem;
			}

			div:last-of-type {
				font-size: 1.7rem;
			}
		}
	}

	&__content {
		font-size: 1.2rem;
	}

	&__line {
		padding-bottom: 14px;
	}

	&__code {
		@extend %text-bold;
		color: white;
		display: inline-block;
		font-size: 1.7rem;
		text-align: center;
		border: 1px solid white;
		padding: 10px;
	}

	&__note {
		font-style: italic;
	}

	&--emphasis {
		@extend %text-bold;
		color: white;
	}

	&__cta {
		margin-top: 10px;
		margin-bottom: 10px;
		cursor: pointer;

		a {
			border: 1px solid white;
			padding: 10px;
			color: white;
		}
	}
}
