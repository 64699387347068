@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.lookalike-product {
	position: relative;
	border-radius: $border-radius-big;
	border: 1px solid $grey-medium;
	display: flex;
	align-items: center;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
	background: white;

	&__photo {
		flex: 1 1 0%;
		height: 100%;
		max-width: 90px;

		.background-progressive-image {
			display: block;
			border-radius: $border-radius-big 0 0 $border-radius-big;

			&__image {
				border-radius: $border-radius-big 0 0 $border-radius-big;
			}
		}

		@include gridle_state(sm) {
			max-width: none; // sur tablet on a bcp de place
		}

		@include gridle_state(md) {
			max-width: 90px;
		}
	}

	&__content {
		height: 100%;
		flex: 2 1 0%;
		padding: 0 12px;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		border-radius: 0 $border-radius-big $border-radius-big 0;
		min-width: 0;
		justify-content: center;

		@include gridle_state(md) {
			padding: 0 12px;
		}
	}

	&__data {
		margin-bottom: 5px;

		.product-destination-title {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			line-height: 0;

			&__region {
				@extend %text-bold;

				font-size: 1.6rem;
				line-height: 120%;
			}

			&__resort {
				font-size: 1.6rem;
				line-height: 120%;
			}
		}

		.product-name-with-category {
			flex-wrap: nowrap;

			&__name {
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				font-size: 12px;
				line-height: 140%;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}

	&__price-type {
		.save-up-to {
			@extend %text-secondary;
			font-size: 1rem;
			letter-spacing: 0;

			> div {
				justify-content: flex-start;
			}

			&__value {
				@extend %text-bold;
				color: $amount-color;
				font-size: 1.8rem;
			}

			&__unit {
				color: $amount-color;
				font-size: 1.2rem;
				margin-top: -5px;
			}
		}

		.from {
			display: block;

			&__price {
				display: block;
				margin-right: 0;
			}

			&__info {
				padding-left: 0;
				border-left: none;
				display: inline-block;
				height: auto;
				color: $grey-dark;
				margin-top: 3px;
			}

			&__flight {
				display: inline-block;

				&::before {
					content: "-";
					padding-right: 5px;
					padding-left: 5px;
				}
			}

			.amount__prefix,
			.amount__suffix {
				font-size: 1rem;
			}

			.amount__value {
				font-size: 1.8rem;
			}
		}

		.product-deal__exclusive {
			@extend %font-bold;
			font-size: 1.8rem;
			text-transform: uppercase;
			margin-top: 5px;
		}

		@include gridle_state(md) {
			display: flex;
			justify-content: space-between;
			flex-wrap: nowrap;
			align-items: flex-end;

			.from {
				&__flight {
					display: block;

					&:before {
						content: "";
						padding: 0;
					}
				}

				.amount__value {
					font-size: 1.8rem;
				}
			}
		}
	}

	&__cta {
		display: block;
		padding: 0 8px 0 0;

		.button {
			width: 32px;
			min-height: 40px;

			svg {
				height: 10px;
				width: 10px;
			}
		}
	}
}
