@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.product-time {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;

	&__prefix {
		margin-right: 5px;
		text-align: center;
	}

	&__value {
		@extend %font-bold;
		margin-right: 5px;

		&:last-of-type {
			margin-right: 0;
		}
	}

	&__time {
		display: inline;
	}
}
