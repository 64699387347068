@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$input-border-radius: none !default;
$input-border: 1px solid $active-color !default;
$input-border-color: $grey-medium !default;
$input-shadow-box: none !default;
$border-radius-big: 5px !default;

.advanced-select {
	$this: &;

	position: relative;
	box-sizing: border-box;

	&__label {
		position: absolute;
		top: 15px;
		left: 15px;
		display: flex;
		align-items: center;
		transition: top 0.1s ease-out, font-size 0.1s ease-out;
		color: $grey-dark;
		pointer-events: none; // fix empeche de saisir sur device ios
	}

	// on met .advanced-select__select au lieu de &__select pour écraser les styles de la lib react-select
	.advanced-select__select {
		&__control {
			min-height: 50px;
			background: white;
			border: 1px solid $input-border-color;
			border-radius: $input-border-radius;
			box-shadow: $input-shadow-box;

			&--is-focused {
				border: $input-border;
				box-shadow: none;
			}

			&--menu-is-open {
				border: $input-border;
				box-shadow: none;
			}
		}

		&__input {
			margin-top: 0;
		}

		&__value-container {
			@extend %font-regular;

			padding-left: 13px; // 13 et pas 15 pour tenir compte des 2px de react-select__single-value
			padding-right: 13px; // 13 et pas 15 pour tenir compte des 2px de react-select__single-value

			// put the cursor at the end of label
			&--has-value {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;

				.advanced-select__select__single-value {
					margin-right: 0;
					width: fit-content;

					.sdp-search-form__suggestion-label {
						@extend %font-regular;

						font-weight: normal;
						white-space: initial;
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						cursor: pointer;
					}
				}

				.advanced-select__select__input-container {
					margin-left: 0;
				}
			}
		}

		&__clear-indicator {
			margin-top: auto;
			padding-right: 17px;

			@include gridle_state(md) {
				padding-right: 16px;
				cursor: pointer;
			}

			svg {
				color: #ccc;
				width: 18px;
			}
		}

		&__indicator-separator {
			display: none;
		}

		&__dropdown-indicator {
			display: none;
		}

		&__menu-notice {
			text-align: left;
		}
	}

	// pour écraser les styles du dropdown de react-select quand c'est en mode portal
	&__select__menu {
		&.advanced-select-menu {
			border: 0;
			box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
			border-radius: $border-radius-big;

			.advanced-select-option {
				background: none;
				cursor: pointer;

				.sdp-search-form__suggestion-picto {
					width: 24px;
					color: $color-primary;

					svg {
						width: 24px;
						max-height: 24px;
					}
				}

				&.advanced-select__select__option--is-selected {
					background: $grey-light !important;
					color: $black;

					.amount {
						&__prefix,
						&__value,
						&__currency {
							color: white;
						}
					}

					@include gridle_state(md) {
						background: transparent;

						&.advanced-select__select__option--is-focused {
							background: $grey-light;
						}
					}
				}

				@include gridle_state(md) {
					&.advanced-select__select__option--is-focused {
						background: $grey-light;
						color: $black;

						&.advanced-select__select__option--is-selected {
							background: $grey-light;
							color: $black;

							.amount {
								&__prefix,
								&__value,
								&__currency {
									color: white;
								}
							}
						}
					}
				}
			}
		}
	}

	&--touched {
		#{$this}__label {
			@extend %input-label-touched;

			top: 6px;
		}
	}

	&--required {
		#{$this}__label {
			&::after {
				content: "*";
			}
		}
	}

	&--error {
		#{$this}__select {
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 10px rgba(197, 6, 18, 0.6);
			border: 1px solid $color-error;
			background: #fff;
		}

		#{$this}__label {
			@extend %font-bold;

			color: $color-error;
		}
	}

	&--with-icon {
		.advanced-select__select {
			&__value-container {
				padding-left: 47px;
			}
		}

		#{$this}__label {
			top: 19px;
			left: 70px;
			padding-left: 0;

			@include gridle_state(md) {
				left: 50px;
			}
		}

		#{$this}__icon {
			width: 18px;
			position: absolute;
			top: 0;
			left: 16px;
			bottom: 0;
			display: flex;
			align-items: center;
			pointer-events: none;

			svg {
				width: 18px;
			}
		}
	}

	// eviter qu'au chargement de la page, des blocs blanc sont visibles sur l’écran
	input {
		@extend %font-regular;

		background: transparent;
		border: none;
	}
}

:global(.advanced-select__select__menu-portal) {
	z-index: 999;
}
