@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
%active-state {
    color: white;
    background: $active-color;
    border-radius: 100%;
    position: relative;
    padding: 0;
}

%select-range-support {
    content: "";
    position: absolute;
    top: 0;
    background: $grey-light;
    width: 50%;
    height: 100%;
    z-index: -1;
}

.date-picker-calendar-tile__tile {
    &.react-calendar__tile {
        &.react-calendar__tile--hasActive {
            @extend %active-state;
        }
        &--range {
            background: $grey-light;
        
            &.react-calendar__tile--rangeStart {
                @extend %active-state;
                overflow: visible !important;
                z-index: 3;

                &:before {
                    @extend %select-range-support;
                    right: 0;
                }
            }
        
            &.react-calendar__tile--rangeEnd {
                @extend %active-state;
                overflow: visible !important;
                z-index: 3;

                &:before {
                    @extend %select-range-support;
                    left: 0;
                }
            }
        }
    }
}